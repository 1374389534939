import { useState, useEffect } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { Link, Events, scrollSpy, animateScroll as scroll } from 'react-scroll';
import logo from '../asset/logo-no-background.png';

const Navbar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 80) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        const currentPath = location.hash ? location.hash.substring(1) : 'home';
        setActiveLink(currentPath);
    }, [location]);

    useEffect(() => {
        Events.scrollEvent.register('begin', function(to, element) {
            // You can handle the beginning of the scroll event if needed
        });

        Events.scrollEvent.register('end', function(to, element) {
            // You can handle the end of the scroll event if needed
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    };

    const handleLogoClick = () => {
        if (location.pathname === '/') {
            scroll.scrollToTop();
        } else {
            navigate('/');
        }
    };

    const handleLinkClick = (section) => {
        if (location.pathname !== '/') {
            navigate('/', { state: { scrollToSection: section } });
        } else {
            onUpdateActiveLink(section);
        }
    };

    return (
        <>
            <nav className={`fixed top-0 left-0 w-full z-50 transition-colors duration-300 ${scrolled ? "bg-white shadow-md" : "bg-transparent py-4"}`}>
                <div className="container mx-auto flex justify-between items-center py-4">
                    <div onClick={handleLogoClick} className="flex items-center text-2xl font-bold font-roboto cursor-pointer">
                        <img src={logo} alt="Breakthrough Logo" className="w-12 h-8 mr-2" />
                        <span className="text-text-primary">Breakthrough</span>
                    </div>
                    <div className="hidden md:flex space-x-6 font-bold font-roboto cursor-pointer">
                        <Link
                            to="home"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className={activeLink === 'home' ? 'text-primary-main' : 'text-text-secondary'}
                            onClick={() => handleLinkClick('home')}
                        >
                            Home
                        </Link>
                        <Link
                            to="services"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className={activeLink === 'services' ? 'text-primary-main' : 'text-text-secondary'}
                            onClick={() => handleLinkClick('services')}
                        >
                            Our Values
                        </Link>
                        <Link
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className={activeLink === 'about' ? 'text-primary-main' : 'text-text-secondary'}
                            onClick={() => handleLinkClick('about')}
                        >
                            About Us
                        </Link>
                        <Link
                            to="connect"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className={activeLink === 'connect' ? 'text-primary-main' : 'text-text-secondary'}
                            onClick={() => handleLinkClick('connect')}
                        >
                            Contact Us
                        </Link>
                    </div>
                </div>
            </nav>
            <Outlet />
        </>
    );
};

export default Navbar;
