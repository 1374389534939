// Home.jsx
import React,{useState} from "react";
import { ReactComponent as IconHero } from '../asset/svg/Hero_Text_BG.svg' ;
import { ReactComponent as IconText } from '../asset/svg/Hero_Text_decor.svg' ;
import { ReactComponent as Star } from '../asset/svg/star.svg' ;
import { ReactComponent as Stat } from '../asset/svg/stat.svg' ;
import { ReactComponent as Money } from '../asset/svg/money.svg' ;
import { ReactComponent as BG } from '../asset/svg/Background.svg' ;
import { ReactComponent as IconInnovative } from '../asset/svg/innovative.svg';
import { ReactComponent as IconPartnership } from '../asset/svg/partnership.svg';
import { ReactComponent as IconQuality } from '../asset/svg/quality.svg';


const Home = () => {

  const [isContacted, setIsContacted] = useState(false);
  const handleContactClick = () => {
    setIsContacted(true);
  };


  const services = [
    {
      title: 'Innovative Solutions',
      description: 'We provide cutting-edge solutions to push the boundaries of technology.',
      icon: <IconInnovative />,
    },
    {
      title: 'Strategic Partnerships',
      description: 'Our strategic partnerships ensure we deliver the best results for our clients.',
      icon: <IconPartnership />,
    },
    {
      title: 'Commitment to Quality',
      description: 'Quality is at the heart of everything we do, driving us to excel in every project.',
      icon: <IconQuality />,
    }
  ];
  

  return (
    <>
      <div className="overflow-hidden scrollbar-hide">
  <BG className="absolute -top-52 -z-20" />
  <section id="home">
    <div className="relative py-12 sm:py-16 lg:pt-20 xl:pb-0">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h1 className="mt-5 text-3xl font-light leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight">
            Innovating for a
            <span className="relative inline-flex justify-center whitespace-nowrap font-bold">
              <IconHero className="absolute -bottom-8 hidden w-2/3 text-gray-400 sm:block" />
              Brighter Tomorrow
            </span>
          </h1>
          <p className="mx-auto mt-12 max-w-xl leading-7 text-gray-600 sm:max-w-2xl">
            At Breakthrough, we innovate to empower industries and individuals alike, providing cutting-edge solutions that drive progress and inspire excellence. Our commitment is to forge pathways that turn visionary ideas into impactful realities, setting new standards across technology, education, and manufacturing.
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-8 relative">
            <div className="group relative mt-10 inline-flex gap-x-6">
              <a href="/discover" className="px-6 py-3 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                Discover Our Work
              </a>
              <IconText className="-scale-x-100 absolute left-10 -bottom-10 hidden h-10 w-10 -rotate-12 text-blue-600 md:inline-flex" />

              <a href="#connect" className="px-6 py-3 bg-gray-100 text-blue-700 font-semibold rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75">
                Join Our Journey
              </a>

            </div>
          </div>
        </div>
      </div>
      <div className="mt-16 mb-16 flex flex-col items-center justify-center divide-y divide-gray-300 sm:flex-row sm:divide-x sm:divide-y-0 md:mt-20">
        <div className="flex max-w-xs space-x-2 px-4 py-4">
          <Star className="h-12 w-12 text-blue-600" />
          <p className="text-gray-600">Empowering industries through innovation and technology.</p>
        </div>
        <div className="flex max-w-xs space-x-2 px-4 py-4">
          <Stat className="h-12 w-12 text-blue-600" />
          <p className="text-gray-600">Driving excellence with data-driven insights and solutions.</p>
        </div>
        <div className="flex max-w-xs space-x-2 px-4 py-4">
          <Money className="h-12 w-12 text-blue-600" />
          <p className="text-gray-600">Delivering value with cost-effective and impactful strategies.</p>
        </div>
      </div>
    </div>
  </section>
        <section>
          <div className="mx-auto my-10 grid max-w-screen-xl grid-cols-1 px-2 text-blue-800 sm:px-20 lg:grid-cols-3">
            <div className="col-span-1 flex flex-col justify-center text-center sm:text-left md:pr-10">
              <h1 className="mb-6 text-4xl">What We Offer</h1>
              <p className="text-blue-900">At Breakthrough, we are committed to innovation and excellence. Here's a glimpse of the transformative services we provide:</p>
            </div>
            <div className="col-span-2 mt-10 grid grid-cols-1 gap-6 rounded-2xl bg-blue-50 p-5 sm:p-10 md:grid-cols-2 lg:mt-0">
              <div className="relative flex gap-5">
                <div className="absolute -left-12 flex h-10 w-10 items-center justify-center rounded-full bg-white text-lg text-blue-200 sm:static sm:bg-transparent md:text-5xl">01</div>
                <div>
                  <h3 className="text-xl font-semibold">Web Development and Design</h3>
                  <p className="text-blue-900 mt-3">Creating user-friendly and visually appealing websites tailored to client needs.</p>
                </div>
              </div>
              <div className="relative flex gap-5">
                <div className="absolute -left-12 flex h-10 w-10 items-center justify-center rounded-full bg-white text-lg text-blue-200 sm:static sm:bg-transparent md:text-5xl">02</div>
                <div>
                  <h3 className="text-xl font-semibold">Software Solutions and Custom Applications</h3>
                  <p className="text-blue-900 mt-3">Developing custom software solutions and applications to address specific business challenges.</p>
                </div>
              </div>
              <div className="relative flex gap-5">
                <div className="absolute -left-12 flex h-10 w-10 items-center justify-center rounded-full bg-white text-lg text-blue-200 sm:static sm:bg-transparent md:text-5xl">03</div>
                <div>
                  <h3 className="text-xl font-semibold">IoT Integration and Hardware Solutions</h3>
                  <p className="text-blue-900 mt-3">Providing comprehensive IoT services, including hardware design and integration, to enhance connectivity and automation.</p>
                </div>
              </div>
              <div className="relative flex gap-5">
                <div className="absolute -left-12 flex h-10 w-10 items-center justify-center rounded-full bg-white text-lg text-blue-200 sm:static sm:bg-transparent md:text-5xl">04</div>
                <div>
                  <h3 className="text-xl font-semibold">IT Consulting and Technical Support</h3>
                  <p className="text-blue-900 mt-3">Offering expert guidance and support to optimize IT infrastructure and solve technical issues.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="py-16" >
          <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6 text-blue-800">Our Values</h2>
          <p className="text-lg mb-12">Explore the core principles that define our values and discover how we can add meaningful impact to your organization.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                  <div className="mb-4">{service.icon}</div>
                  <h3 className="text-2xl font-semibold mb-2 text-blue-800 ">{service.title}</h3>
                  <p className="text-blue-900 mt-3">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="about" className="py-16" >
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-semibold mb-6">About Us</h2>
            <p className="text-lg text-gray-700 mb-8">
              At Breakthrough, we are committed to innovation and excellence. Our journey began with a vision to transform the technology landscape by providing a one-stop solution for all technical needs.
              We recognized the need for a platform that could meet the diverse demands of businesses and individuals. Our mission is to deliver cutting-edge, accessible technology solutions that empower clients to achieve their goals.
              As we grow, we remain focused on innovation, setting new standards in the tech industry, and working towards becoming a leading unicorn startup. We are also dedicated to using our success to address important challenges, such as unemployment in India, by creating meaningful opportunities and driving positive change.
            </p>
            <a href="/about" className="px-6 py-3 bg-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
              Meet our Founders
            </a>
          </div>
        </section>
        <section
          id="cta"
          className={`py-16 bg-blue-700 text-white text-center transition-transform duration-700 ease-in-out ${
            isContacted ? 'slide-out' : ''
          }`} >
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-semibold">
              {isContacted ? 'Feel Free to Contact' : 'Ready to Transform Your Business?'}
            </h2>
            <p className="mt-4 text-gray-100">
              {isContacted
                ? 'We are here to help you. Reach out to us with any questions or inquiries.'
                : 'Join us in pushing the boundaries of what’s possible. Contact us today to learn more about our innovative solutions.'}
            </p>
            {!isContacted && (
              <a
                href="#connect"
                onClick={handleContactClick}
                className="mt-8 inline-block px-6 py-3 bg-white text-blue-700 font-semibold rounded-lg shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75"
              >
                Get in Touch
              </a>
            )}
          </div>
        </section>
        <section id="connect" className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold text-center mb-6">Contact Us</h2>
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/3 mb-8 md:mb-0 text-center">
              <p className="text-gray-700">Email: info@breakthrough.ind.in</p>
              <p className="text-gray-700">Address: Near Prestige Tech Park, Bangalore</p>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
}

export default Home;
