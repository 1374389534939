import React, { useEffect, useState } from 'react';

const Discover = () => {
const [repos, setRepos] = useState([]);
const [error, setError] = useState(null);

useEffect(() => {
    const fetchRepos = async () => {
    try {
    const response = await fetch('https://api.github.com/orgs/Breakthrough-pvt-ltd/repos');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    const filteredRepos = data.filter(repo => repo.name !== '.github');
    setRepos(filteredRepos);
    } catch (error) {
    setError(error.message);
    console.error('Error fetching repositories:', error);
    }
};

fetchRepos();
}, []);

return (
<>
    <div className="overflow-hidden scrollbar-hide mt-36">
    {error && <p style={{ color: 'red' }}>Error: {error}</p>}
    {/* Repositories Section */}
    <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">Discover Our Projects</h2>
        <div className="grid md:grid-cols-2 gap-8">
            {repos.map((repo, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-gray-700 mb-2">
                <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                    {repo.name}
                </a>
                </h3>
                <p className="text-gray-600">{repo.description || 'No description available'}</p>
            </div>
            ))}
        </div>

        {/* Explore More Section */}
        <div className="text-center mt-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Explore More on GitHub</h3>
            <a
            href="https://github.com/Breakthrough-pvt-ltd"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 text-white py-3 px-6 rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
            >
            Visit Our GitHub Organization
            </a>
        </div>
        </div>
    </section>
    </div>
</>
);
};

export default Discover;
