import React from 'react';
import {scroller } from 'react-scroll';

function TermsOfService() {

    React.useEffect(() => {
        // Get the hash from the URL and scroll to the corresponding section
        const hash = window.location.hash;
        if (hash) {
            scroller.scrollTo(hash.replace('#', ''), {
                smooth: true,
                offset: -180, // Adjust based on your fixed header height
                duration: 500
            });
        }
    }, []);

    return (
        <div class="">
            <div class="h-16"></div>
            <div class="bg-white">
                <section class="mx-auto max-w-screen-xl px-4 py-10 sm:px-10" id="terms">
                    <h2 class="w-full text-3xl font-bold text-black sm:text-4xl">Terms of Service</h2>
                    <div class="text-gray-600 mt-6">
                        <p class="mt-2">
                            Welcome to Breakthrough! By accessing our platform, you agree to comply with our terms and conditions. We offer a comprehensive range of technical solutions designed to meet your business needs. Our services are built on innovation, integrity, and a commitment to excellence. It is important that your use of our platform aligns with these terms to ensure a smooth and effective experience for all users.
                        </p>
                        <p class="mt-4">
                            Our terms of service cover the following key areas:
                        </p>
                        <ol class="list-decimal ml-6 mt-4">
                            <li class="mt-2">
                                <strong>Service Usage:</strong> The services provided by Breakthrough are intended for lawful and appropriate use. Users are expected to comply with all applicable laws and regulations while using our platform. Any misuse or unauthorized access may result in termination of services.
                            </li>
                            <li class="mt-4">
                                <strong>Account Responsibilities:</strong> Users are responsible for maintaining the confidentiality of their account information, including passwords. Any activity under a user’s account is their responsibility, and they should notify us immediately of any unauthorized use or security breach.
                            </li>
                            <li class="mt-4">
                                <strong>Service Modifications:</strong> Breakthrough reserves the right to modify, suspend, or discontinue any aspect of our services at any time, with or without notice. We are not liable for any disruptions or loss resulting from such modifications.
                            </li>
                            <li class="mt-4">
                                <strong>Payment and Billing:</strong> For paid services, users agree to pay all fees and charges associated with their account. All payments must be made in a timely manner, and users are responsible for providing accurate billing information.
                            </li>
                            <li class="mt-4">
                                <strong>Intellectual Property:</strong> All content, trademarks, and other intellectual property on the platform are owned by Breakthrough or its licensors. Users are granted a limited, non-exclusive license to access and use the platform for its intended purposes.
                            </li>
                            <li class="mt-4">
                                <strong>Limitation of Liability:</strong> Breakthrough is not liable for any indirect, incidental, or consequential damages arising from the use or inability to use our services. Our liability is limited to the maximum extent permitted by law.
                            </li>
                            <li class="mt-4">
                                <strong>Governing Law:</strong> These terms are governed by the laws of India. Any disputes arising from the use of our services shall be subject to the exclusive jurisdiction of the courts in India.
                            </li>
                        </ol>
                        <p class="mt-4">
                            Please review these terms regularly, as your continued use of our platform constitutes your acceptance of any changes.
                        </p>
                    </div>
                </section>
            </div>
            <div class="bg-gray-100">
                <section class="mx-auto max-w-screen-xl border-b border-gray-300 px-4 py-10 sm:px-10" id="privacy">
                    <h2 class="w-full text-3xl font-bold text-gray-800 sm:text-4xl">Privacy Policy</h2>
                    <div class="text-gray-600 mt-6">
                        <p class="mt-2">
                            At Breakthrough, your privacy is of paramount importance. Our privacy policy outlines how we collect, use, and protect your personal information when you interact with our platform. We are committed to transparency and ensuring that your data is handled securely and responsibly.
                        </p>
                        <p class="mt-4">
                            Our privacy policy covers the following key areas:
                        </p>
                        <ol class="list-decimal ml-6 mt-4">
                            <li class="mt-2">
                                <strong>Data Collection:</strong> We collect personal information such as your name, email address, and payment details when you sign up for our services. We also collect data about your usage of our platform to enhance your experience and improve our services.
                            </li>
                            <li class="mt-4">
                                <strong>Data Usage:</strong> The information we collect is used to provide and improve our services, process transactions, and communicate with you about updates and promotions. We may also use your data for research and analysis to better understand our users’ needs.
                            </li>
                            <li class="mt-4">
                                <strong>Data Sharing:</strong> We do not share your personal information with third parties except as necessary to provide our services (e.g., payment processing) or as required by law. We ensure that any third parties we work with are bound by confidentiality agreements.
                            </li>
                            <li class="mt-4">
                                <strong>Data Security:</strong> We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or alteration. This includes encryption, secure servers, and regular security audits.
                            </li>
                            <li class="mt-4">
                                <strong>Your Rights:</strong> You have the right to access, update, or delete your personal information at any time. You can also opt out of receiving marketing communications from us. If you have any concerns about your privacy, please contact our support team.
                            </li>
                            <li class="mt-4">
                                <strong>Cookies:</strong> We use cookies to enhance your browsing experience and to analyze site traffic. You can manage your cookie preferences through your browser settings.
                            </li>
                            <li class="mt-4">
                                <strong>Policy Updates:</strong> We may update this privacy policy from time to time. Any changes will be posted on this page, and we encourage you to review it regularly to stay informed about how we are protecting your data.
                            </li>
                        </ol>
                        <p class="mt-4">
                            If you have any questions or concerns about our privacy practices, please do not hesitate to contact us.
                        </p>
                    </div>
                </section>
            </div>
            <div class="bg-white">
                <section class="mx-auto max-w-screen-xl px-4 py-10 sm:px-10" id="conduct">
                    <h2 class="w-full text-3xl font-bold text-gray-800 sm:text-4xl">User Conduct</h2>
                    <div class="text-gray-600 mt-6">
                        <p class="mt-2">
                            At Breakthrough, we are dedicated to fostering a positive, professional, and respectful community. To maintain the integrity of our platform, we expect all users to adhere to our code of conduct. This ensures that everyone can benefit from a safe and constructive environment.
                        </p>
                        <p class="mt-4">
                            Our user conduct guidelines cover the following key areas:
                        </p>
                        <ol class="list-decimal ml-6 mt-4">
                            <li class="mt-2">
                                <strong>Respectful Interaction:</strong> All users must interact with others in a respectful and professional manner. Harassment, discrimination, and abusive behavior are strictly prohibited. This applies to all communication on our platform, including messages, comments, and posts.
                            </li>
                            <li class="mt-4">
                                <strong>Content Guidelines:</strong> Users are responsible for the content they share on our platform. Any content that is unlawful, harmful, or violates the rights of others will be removed, and the user may face disciplinary action. We encourage users to report any inappropriate content they encounter.
                            </li>
                            <li class="mt-4">
                                <strong>Security and Integrity:</strong> Users must not attempt to compromise the security of our platform or gain unauthorized access to accounts, systems, or data. Any activities that undermine the integrity of our services, such as hacking or spreading malware, are strictly prohibited.
                            </li>
                            <li class="mt-4">
                                <strong>Fair Usage:</strong> We expect users to use our platform fairly and responsibly. This includes avoiding activities that could overload our systems or disrupt services for others. Misuse of our resources, such as excessive use of bandwidth or storage, may result in restrictions or termination of access.
                            </li>
                            <li class="mt-4">
                                <strong>Reporting Violations:</strong> We encourage users to report any violations of our code of conduct. Our support team will investigate all reports promptly and take appropriate action to address any issues.
                            </li>
                            <li class="mt-4">
                                <strong>Consequences:</strong> Violations of our user conduct guidelines may result in temporary or permanent suspension of access to our platform. We reserve the right to take legal action against users who engage in illegal activities or breach our terms of service.
                            </li>
                            <li class="mt-4">
                                <strong>Continuous Improvement:</strong> We are committed to continuously improving our platform and user experience. Feedback from our users is invaluable, and we encourage you to share your thoughts and suggestions with us.
                            </li>
                        </ol>
                        <p class="mt-4">
                            By adhering to these guidelines, you help us maintain a welcoming and productive environment for everyone. Thank you for being a part of the Breakthrough community.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TermsOfService;
