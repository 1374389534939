import { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

const Footer = () => {
    const [setActiveLink] = useState('home');
    const location = useLocation();
    const navigate = useNavigate();

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    };
    
    const handleLinkClick = (section) => {
        if (location.pathname !== '/') {
            navigate('/', { state: { scrollToSection: section } });
        } else {
            onUpdateActiveLink(section);
            // Additional logic if needed to scroll to the section
        }
    };

    return (
        <footer className="py-6 bg-gray-800 text-white">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/3 mb-6 md:mb-0">
                        <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
                        <ul>
                            <li><Link to="/about" className="hover:underline">About Us</Link></li>
                            <li><p onClick={() => handleLinkClick('connect')} className="hover:underline cursor-pointer">Contact Us</p></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/3 mb-6 md:mb-0">
                        <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
                        <div className="flex space-x-4">
                            <a href="https://www.linkedin.com/company/breakthrough-pvt-ltd" target='blank' className="text-white hover:text-gray-400">LinkedIn</a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/3">
                        <h3 className="text-xl font-semibold mb-4">Legal</h3>
                        <ul>
                            <li>
                                <Link to="/terms-of-service#terms" className="hover:underline">Terms of Service</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-service#privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-service#conduct" className="hover:underline">User Conduct</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center mt-6">
                    <p className="text-gray-400">© {new Date().getFullYear()} Breakthrough. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
