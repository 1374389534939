import React from 'react'
import image from '../asset/alfred.jpg'
import image2 from '../asset/noeal.jpg'


const founder = [
    {
        name: 'Noeal Rajeev',
        bio: ' Founder and Chief Technical Officer at Breakthrough Pvt. Ltd., excels in developing innovative hardware and software solutions. With expertise in IoT, embedded systems, and data analytics, he is committed to tackling real-world challenges through advanced technology.',
        image: image2
    },
    {
        name: 'Alfred Shaju',
        bio: 'is the Co-Founder and CEO of Breakthrough Pvt. Ltd., where he drives innovation by merging business strategy with technical expertise. With a strong foundation in both areas, he leads the company toward sustainable growth and industry leadership.',
        image: image,
    },
    ];

// const teamMembers = [
// {
//     name: 'Team Member 1',
//     role: 'Role 1',
//     image: 'path_to_team_member_1_image.jpg', // Replace with actual path to team member's image
// },
// {
//     name: 'Team Member 2',
//     role: 'Role 2',
//     image: 'path_to_team_member_2_image.jpg', // Replace with actual path to team member's image
// },
// // Add more team members as needed
// ];
const FoundersPage = () => {
return (
    <div className="overflow-hidden scrollbar-hide mt-36 " >
    {/* Founders Section */}
    <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">Meet the Founders</h2>
        <div className="grid md:grid-cols-2 gap-8">
            {founder.map((founder, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg text-center">
                <img
                src={founder.image}
                alt={founder.name}
                className="w-32 h-32 rounded-full mx-auto mb-4"
                />
                <h3 className="text-2xl font-semibold text-gray-700 mb-2">{founder.name}</h3>
                <p className="text-gray-600">{founder.bio}</p>
            </div>
            ))}
        </div>
        </div>
    </section>

    {/* Team Section
    <section className="bg-white py-12">
        <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">Our Team</h2>
        <div className="grid md:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
            <div key={index} className="bg-gray-100 p-6 rounded-lg shadow-lg text-center">
                <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mx-auto mb-4"
                />
                <h3 className="text-2xl font-semibold text-gray-700 mb-2">{member.name}</h3>
                <p className="text-blue-600">{member.role}</p>
            </div>
            ))}
        </div>
        </div>
    </section> */}
    </div>
);
};

export default FoundersPage;
