import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import FoundersPage from './pages/About';
import Navbar from './components/Navbar';
import TermsOfService from './pages/TermsOfService';
import Discover from './pages/Discover';
import Footer from './components/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <Navbar /> 
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<FoundersPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/discover" element={<Discover />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
